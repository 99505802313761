import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import * as embeddedRichtext from '../../configs/embedded-richtext';

import { richTextPropType } from '~/shared/util/shared-prop-types';

const DATA_SELECTOR = 'embedded_richtext';

const containerStyles = ({ textAlign = EmbeddedRichtext.LEFT }) => css`
  text-align: ${textAlign};
`;
const Container = styled('div')(containerStyles);

/**
 * Embedded rich text into rich text.
 */
function EmbeddedRichtext({ content, textAlign }) {
  return (
    <Container dataSelector={DATA_SELECTOR} textAlign={textAlign}>
      {documentToReactComponents(content, embeddedRichtext)}
    </Container>
  );
}

EmbeddedRichtext.LEFT = 'left';
EmbeddedRichtext.RIGHT = 'right';
EmbeddedRichtext.CENTER = 'center';

EmbeddedRichtext.propTypes = {
  content: richTextPropType,
  textAlign: PropTypes.oneOf([
    EmbeddedRichtext.LEFT,
    EmbeddedRichtext.RIGHT,
    EmbeddedRichtext.CENTER,
  ]),
  contentType: PropTypes.string,
};

/**
 * @component
 */
export default EmbeddedRichtext;
