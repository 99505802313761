import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import { Theme } from '@emotion/react';

import { Alignment, AssetFile } from './interfaces';

import * as sizeCalculator from '~/shared/util/media-size-calculator';
import { ALIGNMENT } from '~/shared/constants';

const { RIGHT, LEFT } = ALIGNMENT;

const maxWidth = 1200;
const breakpointSizesMap = {
  kilo: 900,
  mega: 1200,
  giga: 1500,
  tera: 2000,
};

export function getWidth(file: AssetFile) {
  const width = get('details.image.width', file);
  return width && width < maxWidth ? width : maxWidth;
}

export function getHeight(width: number, aspectRatio: number) {
  return sizeCalculator.getHeightFromWidth(width, aspectRatio);
}

export function getSrcSet(src: string, aspectRatio: number = 1.66) {
  return Object.values(breakpointSizesMap).map((width) => {
    const height = getHeight(width, aspectRatio);
    const size = `${width}w`;
    return { src, width, height, size };
  });
}

export function getSizes(theme: Theme) {
  return Object.entries(breakpointSizesMap)
    .reduce((acc, [breakpointName, size]) => {
      const breakpoint = theme?.breakpoints?.[breakpointName];
      if (breakpoint) {
        acc.push(`${breakpoint} ${size}px`);
      }
      return acc;
    }, [])
    .concat('100vw')
    .join(', ');
}

export function getAspectRatio(file: AssetFile) {
  const width = get('details.image.width', file);
  const height = get('details.image.height', file);

  return sizeCalculator.getAspectRatio(width, height);
}

export function checkIsCentered(align: Alignment) {
  return !includes(align, [RIGHT, LEFT]);
}
