import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Align from '../Align';
import Caption from '../Caption';

import SiteContext from '~/shared/providers/SiteContext';
import { richTextPropType } from '~/shared/util/shared-prop-types';
import Video from '~/shared/components/Video';

/**
 * Embed a video into rich text.
 */
function EmbeddedVideo({
  align = Align.CENTER,
  className,
  caption,
  allowFullScreen = true,
  autoplay = false,
  loop = false,
  mute = false,
  hideControls = false,
  removeBranding = true,
  showRelatedVideos = false,
  aspectRatio,
  ...video
}) {
  const { locale = '' } = useContext(SiteContext);
  const language = locale.slice(0, 2);

  if (!video.src) {
    return null;
  }

  return (
    <Align align={align} className={className}>
      <figure>
        <Video
          language={language}
          allowFullScreen={allowFullScreen}
          autoplay={autoplay}
          loop={loop}
          mute={mute}
          hideControls={hideControls}
          removeBranding={removeBranding}
          showRelatedVideos={showRelatedVideos}
          aspectRatio={aspectRatio}
          {...video}
        />
        {caption && <Caption caption={caption} />}
      </figure>
    </Align>
  );
}

EmbeddedVideo.LEFT = Align.LEFT;
EmbeddedVideo.RIGHT = Align.RIGHT;
EmbeddedVideo.CENTER = Align.CENTER;
EmbeddedVideo.WIDE = Align.WIDE;

EmbeddedVideo.propTypes = {
  align: PropTypes.oneOf([
    EmbeddedVideo.LEFT,
    EmbeddedVideo.RIGHT,
    EmbeddedVideo.CENTER,
    EmbeddedVideo.WIDE,
  ]),
  caption: richTextPropType,
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  allowFullScreen: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  mute: PropTypes.bool,
  hideControls: PropTypes.bool,
  removeBranding: PropTypes.bool,
  showRelatedVideos: PropTypes.bool,
  aspectRatio: PropTypes.number,
};

/**
 * @component
 */
export default EmbeddedVideo;
