import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Script from 'next/script';

import { getSandboxParams } from './EmbeddedFrameService';

import SiteContext from '~/shared/providers/SiteContext';
import * as Url from '~/shared/services/url';

const wrapperStyles = ({ theme, ratio }) => css`
  clear: both;
  position: relative;
  overflow: hidden;
  padding-top: ${ratio}%;
  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.giga};
  background: var(--cui-bg-subtle);
`;

const Wrapper = styled('div')(wrapperStyles);

const placeholderStyles = () => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  z-index: 1;
  color: var(--cui-fg-placeholder);
  text-align: center;
`;

const Placeholder = styled('span')(placeholderStyles);

const iframeStyles = () => css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 2;
`;

const IFrame = styled('iframe')(iframeStyles);

/**
 * Embed an iframe into rich text.
 */
function EmbeddedFrame({
  src = '',
  title = '',
  width,
  height,
  allowFullScreen,
}) {
  const site = useContext(SiteContext);

  if (!src) {
    return null;
  }

  const sandboxParams = getSandboxParams(src, site.trustedHosts);
  const ratio = (height && width ? (height / width) * 100 : 56.25).toFixed(2);
  const ratioStorylane = (
    height && width ? (height / width) * 100 : 83.25
  ).toFixed(2);
  const allowedHosts = ['app.storylane.io'];

  if (allowedHosts.includes(Url.parse(src).host)) {
    return (
      <Wrapper ratio={ratioStorylane}>
        <Placeholder aria-hidden>{title}...</Placeholder>

        <Script
          src="https://js.storylane.io/js/v1/storylane.js"
          strategy="lazyOnload"
        ></Script>
        <IFrame src={src} title={title} allowFullScreen={allowFullScreen} />
      </Wrapper>
    );
  }

  return (
    <Wrapper ratio={ratio}>
      <Placeholder aria-hidden>{title}...</Placeholder>
      <IFrame
        src={src}
        title={title}
        allowFullScreen={allowFullScreen}
        frameBorder="0"
        referrerPolicy="no-referrer"
        sandbox={sandboxParams.join(' ')}
      />
    </Wrapper>
  );
}

EmbeddedFrame.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  allowFullScreen: PropTypes.bool,
};

/**
 * @component
 */
export default EmbeddedFrame;
