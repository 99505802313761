import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import RichText from '../../RichText';
import * as inline from '../../configs/inline';
import { richTextPropType } from '../../../../util/shared-prop-types';

const INFO = 'Info';
const WARNING = 'Warning';
const DANGER = 'Danger';
const SUCCESS = 'Success';

const backgroundColorMap = {
  [INFO]: 'var(--cui-bg-accent)',
  [WARNING]: 'var(--cui-bg-warning)',
  [DANGER]: 'var(--cui-bg-danger)',
  [SUCCESS]: 'var(--cui-bg-success)',
};
const borderColorMap = {
  [INFO]: 'var(--cui-border-accent)',
  [WARNING]: 'var(--cui-border-warning)',
  [DANGER]: 'var(--cui-border-danger)',
  [SUCCESS]: 'var(--cui-border-success)',
};

const baseStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.giga};
  padding: ${theme.spacings.kilo} ${theme.spacings.mega};
  border-left-width: 4px;
  border-style: solid;
  border-radius: ${theme.borderRadius.mega};
`;

const backgroundStyles = ({ type }) => {
  const backgroundColor = backgroundColorMap[type];
  const borderColor = borderColorMap[type];

  return css`
    background-color: ${backgroundColor};
    border-color: ${borderColor};
  `;
};

const Background = styled('div')(baseStyles, backgroundStyles);

/**
 * Highlight a section of text.
 */
function EmbeddedHighlight({ type = INFO, content = {} }) {
  if (isEmpty(content)) {
    return null;
  }

  return (
    <Background type={type}>
      <RichText
        richText={content}
        renderMark={inline.createRenderMark()}
        renderNode={inline.createRenderNode(inline.ONE)}
      />
    </Background>
  );
}

EmbeddedHighlight.INFO = INFO;
EmbeddedHighlight.WARNING = WARNING;
EmbeddedHighlight.DANGER = DANGER;
EmbeddedHighlight.SUCCESS = SUCCESS;

EmbeddedHighlight.propTypes = {
  type: PropTypes.oneOf([INFO, WARNING, DANGER, SUCCESS]),
  content: richTextPropType.isRequired,
};

/**
 * @component
 */
export default EmbeddedHighlight;
