import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ALIGNMENT } from '~/shared/constants';

const { RIGHT, LEFT, CENTER, WIDE } = ALIGNMENT;

const baseStyles = ({ theme }) => css`
  clear: both;
  margin-top: ${theme.spacings.tera};
  margin-bottom: ${theme.spacings.tera};
  text-align: center;
`;

const rightStyles = ({ theme, align }) =>
  align === RIGHT &&
  css`
    text-align: left;

    ${theme.mq.kilo} {
      float: right;
      margin-top: ${theme.spacings.kilo};
      margin-bottom: ${theme.spacings.mega};
      padding-left: ${theme.spacings.peta};
      width: calc(50% + ${theme.spacings.peta} / 2);
    }

    ${theme.mq.mega} {
      margin-right: -26.66%;
      width: calc(26.66% * 2 + ${theme.spacings.peta});
    }
  `;

const leftStyles = ({ theme, align }) =>
  align === LEFT &&
  css`
    text-align: left;

    ${theme.mq.kilo} {
      float: left;
      margin-top: ${theme.spacings.kilo};
      margin-bottom: ${theme.spacings.mega};
      padding-right: ${theme.spacings.peta};
      width: calc(50% + ${theme.spacings.peta} / 2);
    }

    ${theme.mq.mega} {
      margin-left: -26.66%;
      width: calc(26.66% * 2 + ${theme.spacings.peta});
    }
  `;

const wideStyles = ({ theme, align }) =>
  align === WIDE &&
  css`
    ${theme.mq.mega} {
      margin: ${theme.spacings.exa} -26.66%;
      width: calc(100% + 26.66% * 2);
    }
  `;

const Align = styled('div')(baseStyles, rightStyles, leftStyles, wideStyles);

Align.RIGHT = RIGHT;
Align.LEFT = LEFT;
Align.CENTER = CENTER;
Align.WIDE = WIDE;

Align.propTypes = {
  align: PropTypes.oneOf([RIGHT, LEFT, CENTER, WIDE]),
};

Align.defaultProps = {
  align: CENTER,
};

/**
 * @component
 */
export default Align;
