import * as urlService from '~/shared/services/url';

export function getSandboxParams(src = '', trustedHosts = []) {
  const sandboxParams = [
    'allow-forms',
    'allow-presentation',
    'allow-scripts',
    'allow-popups',
    'allow-popups-to-escape-sandbox',
    'allow-downloads',
  ];
  const { host } = urlService.parse(src);
  const isTrusted = trustedHosts.indexOf(host) > -1;

  if (isTrusted) {
    sandboxParams.push('allow-same-origin');
  }

  return sandboxParams;
}
