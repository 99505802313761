import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import RichText from '../../RichText';
import * as inline from '../../configs/inline';

import { richTextPropType } from '~/shared/util/shared-prop-types';

const wrapperStyles = ({ theme }) => css`
  display: block;
  margin-top: ${theme.spacings.giga};
  letter-spacing: 0.2px;
  color: var(--cui-fg-subtle);
`;

const Wrapper = styled('figcaption')(wrapperStyles);

function Caption({ caption = {}, size = Caption.TWO }) {
  if (isEmpty(caption)) {
    return null;
  }
  return (
    <Wrapper>
      <RichText
        richText={caption}
        renderMark={inline.createRenderMark(size)}
        renderNode={inline.createRenderNode(size)}
      />
    </Wrapper>
  );
}

Caption.TWO = inline.TWO;
Caption.ONE = inline.ONE;

Caption.propTypes = {
  caption: richTextPropType,
  size: PropTypes.oneOf([Caption.TWO, Caption.ONE]),
};

/**
 * @component
 */
export default Caption;
