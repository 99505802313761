import React, { FC, Fragment } from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { Document } from '@contentful/rich-text-types';

import Caption from '../Caption';

import * as EmbeddedImageService from './EmbeddedImageService';
import { Asset, Alignment } from './interfaces';

import { ALIGNMENT } from '~/shared/constants';
import Link from '~/shared/components/Link';
import Image from '~/shared/components/Image';
import dataSelector from '~/shared/util/data-selector';

const { CENTER } = ALIGNMENT;
const DATA_SELECTOR = 'embedded_image';

// NOTE: Don't scale images wider than their original width.
const maxWidthStyles = ({
  maxWidth,
  centered,
}: {
  maxWidth?: string;
  centered?: boolean;
}) => css`
  ${maxWidth &&
  css`
    max-width: ${maxWidth}px;
  `}
  ${centered &&
  css`
    margin: 0 auto;
  `}
`;
const MaxWidth = styled('div')(maxWidthStyles);

const imageStyles = () => css`
  img {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
    will-change: transform;
    backface-visibility: hidden;
  }

  a:hover &,
  a:focus & {
    &::after {
      opacity: 0.1;
    }

    img {
      transform: scale(1.04);
    }
  }
`;

// @ts-expect-error TBD: ignoring because Image component needs TS rewrite to comply w/o "overload" issues
const StyledImage = styled(Image)(imageStyles);

const renderImage = ({ isCentered, caption, imageProps }) => (
  <Fragment>
    <MaxWidth maxWidth={imageProps.width} centered={isCentered}>
      <StyledImage {...imageProps} />
    </MaxWidth>
    {caption && <Caption caption={caption} />}
  </Fragment>
);

export interface EmbeddedImageProps {
  theme?: Theme;
  id?: string;
  contentType?: string;
  name?: string;
  className?: string;
  align?: Alignment;
  caption?: Document;
  trackingId?: string;
  optimizelyFullStackClickEvents?: string[];
  link?: string;
  altText?: string;
  image?: Asset;
}

/**
 * Embed an image into rich text.
 */
const EmbeddedImage: FC<EmbeddedImageProps> = ({
  theme,
  align = CENTER,
  className = '',
  caption = {},
  contentType,
  trackingId,
  optimizelyFullStackClickEvents,
  name,
  id,
  link,
  altText = '',
  image = {},
}) => {
  const src = get('file.url', image);

  if (isEmpty(image) || !src) {
    return null;
  }

  const aspectRatio = EmbeddedImageService.getAspectRatio(image.file);
  const width = EmbeddedImageService.getWidth(image.file);
  const height = EmbeddedImageService.getHeight(width, aspectRatio);
  const srcSet = EmbeddedImageService.getSrcSet(src, aspectRatio);
  const sizes = EmbeddedImageService.getSizes(theme);
  const isCentered = EmbeddedImageService.checkIsCentered(align);

  const imageProps = {
    width,
    height,
    srcSet,
    sizes,
    src,
    aspectRatio,
    alt: altText,
  };

  return (
    <figure className={className}>
      {link ? (
        <Link
          href={link}
          trackingId={trackingId}
          optimizelyFullStackClickEvents={optimizelyFullStackClickEvents}
          trackingContentEntry={{
            contentType,
            contentEntryName: name,
            contentEntryId: id,
          }}
        >
          <a data-selector={dataSelector('image_link', DATA_SELECTOR)}>
            {renderImage({ imageProps, caption, isCentered })}
          </a>
        </Link>
      ) : (
        renderImage({ imageProps, caption, isCentered })
      )}
    </figure>
  );
};

/**
 * @component
 */
export default EmbeddedImage;
